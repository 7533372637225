import './App.css';
// Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// Bootstrap Bundle JS
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Reset from './pages/Reset';
import Unlock from './pages/Unlock';
import Dashboard from './pages/Dashboard';
import Init from './components/Init';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard />}>
          <Route path='/' element={<Init />} />
          <Route path='/Ad_reset' element={<Reset />} />
          <Route path='/Ad_unlock' element={<Unlock />} />
          <Route path='*' element={<Navigate to='/' replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
