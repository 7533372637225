import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { ValidarVigenciaCodigo } from '../../api/usuario';
import ModalEspera from '../ModalEspera';

const PasoDos = ({ setPaso, setActivo3, usuario, usuarioAD }) => {
  const [codigoValidacion, setCodigoValidacion] = useState('');
  const [modalVisible, setModalVisible] = useState(false);

  const limitarEscritura = (event) => {
    if (event.key === 'Enter') {
      ValidarUsuario();
    }
  };

  const ValidarUsuario = async () => {
    if (codigoValidacion === null || codigoValidacion === '') {
      toast.warn('Debe ingresar el código de verificación.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      return;
    } else {
      const value = await ValidarVigenciaCodigo(
        codigoValidacion,
        usuarioAD,
        1,
        'null',
        'null'
      );

      setModalVisible(false);

      if (!value) {
        return;
      }

      setActivo3(true);
      setPaso(3);

      // if (value.mensaje === 'OK') {
      //   setActivo3(true);
      //   setPaso(3);
      // } else {
      //   let mensajeToast = '';

      //   if (value.mensaje === 'E_USUARIO') {
      //     mensajeToast =
      //       'El usuario no existe o la acción no esta permitida, notifique a la Mesa de Ayuda.';
      //   } else if (value.mensaje === 'E_CNX_LDAP') {
      //     mensajeToast =
      //       'El usuario no existe o la acción no esta permitida, notifique a la Mesa de Ayuda.';
      //   } else {
      //     mensajeToast = value.mensaje;
      //   }

      //   toast.warn(mensajeToast, {
      //     position: 'top-right',
      //     autoClose: 5000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: 'light',
      //   });
      // }
    }
  };

  return (
    <div>
      <div className='card-header m-0 p-0'>
        <h6>
          {' '}
          <b>Ingrese el código de validación</b>{' '}
        </h6>
      </div>
      <div className='card-body p-1'>
        <p>
          Se ha generado un código de verificación y enviado en un mensaje SMS
          al número móvil registrado en su perfil de cuenta de red. El mensaje
          SMS puede tomar algún tiempo dependiendo de varios factores como
          ubicación, fuerza de señal o carga en su operador móvil. Recuerde que
          este código de verificación tiene una vigencia de 15 minutos desde que
          fue solicitado.
        </p>
        <div className='position-relative form-group'>
          <label>
            <b>Código de validación</b>
          </label>
          <input
            name='txtCodigoValidacion'
            id='txtCodigoValidacion'
            placeholder='Código de validación'
            type='text'
            className='form-control'
            maxLength='8'
            onKeyDown={limitarEscritura}
            onChange={(e) => {
              setCodigoValidacion(e.currentTarget.value);
            }}
          />
        </div>
      </div>
      <div className='divider'></div>
      <div className='clearfix'>
        <button
          id='btnSolicitar'
          className='btn-shadow btn-wide float-right btn-pill btn-hover-shine btn btn-primary bgCustom'
          onClick={ValidarUsuario}
        >
          Solicitar
        </button>
      </div>
      <ModalEspera
        mensaje='Cargando... Espere por favor.'
        visible={modalVisible}
      />
    </div>
  );
};

export default PasoDos;
