import React from 'react';
import '../styles/modalEspera.css';

const ModalEspera = ({ mensaje, visible }) => {
  return (
    <>
      {visible && <div className='modal-backdrop fade show'></div>}
      <div
        className={`modal fade ${visible ? 'show' : ''}`}
        tabIndex='-1'
        style={{ display: visible ? 'block' : 'none' }}
      >
        <div
          className='modal-dialog'
          style={{ width: '300px', margin: '16rem auto' }}
        >
          <div className='modal-content'>
            <button
              type='button'
              className='close d-none'
              data-dismiss='modal'
              aria-label='Close'
            ></button>
            <div className='modal-body'>
              <div
                id='modal-content'
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <div className='loader-wrapper d-flex justify-content-center align-items-center'>
                  <div className='loader'>
                    <div className='line-scale'>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
              <div style={{ textAlign: 'center' }}>{mensaje}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalEspera;
