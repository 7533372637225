import React from 'react';
import unlockImg from '../images/iconos/unlock.png';
import keyImg from '../images/iconos/key.png';
import { Outlet, useNavigate } from 'react-router-dom';

const Init = () => {
  const navigate = useNavigate();

  return (
    <div className='mx-auto app-login-box col-sm-12 col-md-12 col-lg-10'>
      <div className='card mt-3'>
        <div className='m-2 p-3 d-flex'>
          <div className='col-4 text-center mt-4'>
            <img src={unlockImg} width='100' />
          </div>
          <div className='col-8 mt-2'>
            <h5 className='font-family'>DESBLOQUEO DE CUENTA</h5>
            <h6 className='font-family'>
              Desbloquea tu cuenta de red de forma inmediata.
            </h6>
            <p className='font-family small'>
              Ten en cuenta que deberás de contar con un número móvil
              registrado.
            </p>
            <div>
              <div className='mt-4 d-flex align-items-end flex-column'>
                <h6 className='mb-0'></h6>
                <div className='ml-auto'>
                  <button
                    className='btn btn-primary btn-lg font-family customButton'
                    onClick={() => {
                      navigate('/Ad_unlock');
                    }}
                    // style="background-color:#005293;" onclick="DesbloquearCuenta();"
                  >
                    Ingresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card mt-4'>
        <div className='m-2 p-3 d-flex'>
          <div className='col-4 text-center mt-4'>
            <img src={keyImg} width='100' />
          </div>
          <div className='col-8 mt-2'>
            <h5 className='font-family'>CAMBIO DE CONTRASEÑA</h5>
            <h6 className='font-family'>
              Ahora puedes realizar el cambio de tu contraseña de red en dos
              pasos.
            </h6>
            <p className='font-family small'>
              Ten en cuenta que tu cuenta de red no debe de estar bloqueado por
              intentos y tener un número móvil registrado.
            </p>
            <div>
              <div className='m-0 d-flex align-items-end flex-column'>
                <h6 className='mb-0'></h6>
                <div className='ml-auto'>
                  <button
                    className='btn btn-primary btn-lg font-family customButton'
                    onClick={() => {
                      navigate('/Ad_reset');
                    }}
                    // style="background-color:#005293;" onclick="CambiarContrasena();"
                  >
                    Ingresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='divider'></div>
      <h6
        className='font-family footerLetterTitle'
        // style="color:gray;font-size:.9em;"
      >
        Podemos ayudarte en los siguientes puntos de contacto
      </h6>
      <div className='card-footer row'>
        <div className='col-md-12 text-right'>
          <div
            className='mb-2 mr-2 btn-icon footerLetter'
            // style="color:gray;float:left;"
          >
            {/* <img src="~/Content/Imagenes/iconos/telephone.png" width="20" /> */}
            Central Minsur: 2158330 / Anexo: 8000 | Teléfono Directo MDA:
            6129345 | <b>Whastapp (Texto): 974641169</b>
          </div>
          <div
            className='mb-2 mr-2 btn-icon footerLetter'
            // style="color:gray;float:left;"
          >
            {/* <img src="~/Content/Imagenes/iconos/telephone.png" width="20" /> */}
            Celulares: 913209962 - 989066952 - 949073859 | Correo:
            mesadeayuda@minsur.com |
            <a href='https://teams.microsoft.com/l/chat/0/0?users=28:d89fd7b6-74d5-4d64-9c0a-de6edd2e776b'>
              Microsoft Teams
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Init;
