import React from 'react';
import unlockImg from '../images/iconos/unlock.png';
import keyImg from '../images/iconos/key.png';
import { Outlet, useNavigate } from 'react-router-dom';
import '../styles/dashboard.css';
import '../styles/styles.css';
const Dashboard = () => {
  return (
    <div className=' app-theme-white body-tabs-shadow'>
      <div className='app-container'>
        <div className='h-100 d-flex'>
          <div className='d-none d-lg-block col-lg-4'>
            <div className='backgroundOficina h-100'>
              <div className='backgroundOficinaContent slider-content'>
                <h3>Cuenta de red</h3>
                <p>
                  Desbloquea tu cuenta de red o cambia tu contraseña de forma
                  inmediata.
                </p>
              </div>
            </div>
          </div>
          <div className='h-100 d-flex bg-gray justify-content-center align-items-center col-md-12 col-lg-8'>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
