import React, { useEffect, useState } from 'react';
import unlockImg from '../images/iconos/unlock.png';
import { useNavigate } from 'react-router-dom';
import PasoUno from '../components/Reset/PasoUno';
import PasoDos from '../components/Reset/PasoDos';
import PasoTres from '../components/Reset/PasoTres';
import { ToastContainer } from 'react-toastify';

import '../styles/reset.css';
import 'react-toastify/dist/ReactToastify.css';

const Reset = () => {
  const navigate = useNavigate();
  const [paso, setPaso] = useState(1);
  const [usuario, setUsuario] = useState();
  const [activo1, setActivo1] = useState(true);
  const [activo2, setActivo2] = useState(false);
  const [activo3, setActivo3] = useState(false);
  const [usuarioAD, setUsuarioAD] = useState('');

  useEffect(() => {
    document.title = 'Cuenta de Red - Cambio de contraseña';
  }, []);

  const cambiarPaso = () => {
    if (paso === 1) {
      return (
        <PasoUno
          setPaso={setPaso}
          setActivo2={setActivo2}
          setUsuario={setUsuario}
          setUsuarioAD={setUsuarioAD}
        />
      );
    }
    if (paso === 2) {
      return (
        <PasoDos
          setPaso={setPaso}
          setActivo3={setActivo3}
          usuario={usuario}
          usuarioAD={usuarioAD}
        />
      );
    }
    if (paso === 3) {
      return <PasoTres />;
    }
  };

  return (
    <div>
      <div className='mx-auto app-login-box col-sm-12 col-md-12 col-lg-8'>
        <div className='card mt-4'>
          <div className='m-2 p-3 row'>
            <div className='col-md-1'>
              <img src={unlockImg} width='40' />
            </div>
            <div className='col-md-11 mt-3'>
              <h5 className='font-family color-fuente'>
                CAMBIO DE CONTRASEÑA DE RED
              </h5>
            </div>

            <div className='card-body'>
              <div id='' className='forms-wizard-alt'>
                <ul className='forms-wizard'>
                  <li id='paso1' className={activo1 ? 'active' : ''}>
                    <a>
                      <em>1</em>
                      <span>Paso 1</span>
                    </a>
                  </li>
                  <li id='paso2' className={activo2 ? 'active' : ''}>
                    <a>
                      <em>2</em>
                      <span>Paso 2</span>
                    </a>
                  </li>
                  <li id='paso3' className={activo3 ? 'active' : ''}>
                    <a>
                      <em>3</em>
                      <span>Paso 3</span>
                    </a>
                  </li>
                </ul>
                <div className='form-wizard-content'>{cambiarPaso()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position='top-right'
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light'
      />
    </div>
  );
};

export default Reset;
